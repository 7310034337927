  /* Divers */
@keyframes zoom {
  from {
    transform: scale(1.25, 1.25);
  }

  to {
    transform: scale(1, 1);
  }
}

.nav-min-height { /* NEW */
  min-height: 71px;
}

.toggler-min-height { /* NEW */
  min-height: 55px;
}

.mt-main {  /* Margin top du main = hauteur header*/
  margin-top: 71px;
}

.h-0 {
  height: 0 !important;
}

.overflow-center {
  margin-left: -100%;
  margin-right: -100%;
}

.mb-6 {
  margin-bottom: 5rem;
}

.mt-25 {
  margin-top: 25vh;
}

.mt-2rem {
  margin-top: 2rem;
}

.z-1000 {
  z-index: 1000;
}

.opacity-40 {
  opacity: 0.4 !important;
}

.blur-2 {
  filter: blur(2px);
}

.text-shadow {
  text-shadow: 2px 2px 2px #212529;
}

.scroll-top {
  display: none;
}

.link-hover-underline {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.posts-links { /* couleur primaire ou secondaire (voire une variante) */
  .page-link {
    color: #b0b0b1; /* couleur générique */
    &:hover {
      color: #d99811;
      background-color: #f8f9fa; /* couleur générique */
    }
    &:focus {
      color: #d99811;
      background-color: #f8f9fa; /* couleur générique */
      box-shadow: none;
    }
  }

	.page-link.active {
    color: #fff;
		background-color: #d99811;
		border-color: #d99811;
	}

	.active {
		>.page-link {
      color: #fff;
			background-color: #d99811;
			border-color: #d99811;
		}
	}
}

.main-bg {
  .section-bg {
    &:nth-child(2n+1) {
      background-color: #fff !important;
    }
  }
}

.section-min-height {
  min-height: 620px;
}

  /* Navbar */
.logo-size {
  max-height: 45px;
}
  
  /* Carrousel */

.carousel-inner {
  .carousel-item {
    .slider {
      animation-name: zoom;
      animation-duration: 30s;
      animation-fill-mode: forwards;
      width: 100%;
      height:100%;
    }
  }
}

.slide-overlay{
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
  position: absolute;
  top: 0;
}

.banner-title {
  font-size: calc(1rem + 2vw);
  font-weight: 600;
  line-height: 1.2;
  position: relative;
}

.banner-text {
  font-size: calc(0.85rem + 0.5vw);
  font-weight: 600;
  line-height: 1.2;
}

.banner-text-min-h {
  // min-height: 150px;
  min-height: 120px;
}

// .banner-text-min-hb {
//   min-height: 96px;
// }

.banner-overlay {
  position: absolute;
  top: 0;
  left: 50%;
  width: 1920px;
  height: 100%;
  margin-left: -960px;
  background-color: #212529;
  opacity: 0.3;
}

.img-carousel-mobile  {
  height: 300px !important;
  width: auto;
}

  /* Section Présentation */

.intro-height {
  min-height: 100px; 
}

/* Section agrément */
.approval-logo-size {
  max-height: 120px;
}

/* Section Valeurs */
.value-image {
  max-width: 276px;
  margin: auto;
}

.value-description {
  padding: 0 1rem;
}

/* Section Avis */

.owl-dots {
  text-align: center;
}

.section-bg {
  .owl-carousel {
    button.owl-dot {
      height: 1.5rem;
      width: 1.5rem;
      border-radius: 5px;
      margin: 0 5px;
      background: #fff;
      &.active {
        background: #d99811 !important;
      }
    }
  }
}

.main-bg {
  .section-bg {
    &:nth-child(2n+1) {
      .owl-carousel {
        button.owl-dot {
          background: #efefef;
        }
      }
    }
  }
}

.review-link {
  margin-right: 3rem;
}

.testimonial {
  margin: 0 16px 40px;
}

.testimonial .testimonial-content {
  padding: 30px 25px;
  margin-bottom: 35px;
  background: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  height: 275px;
}

.testimonial .testimonial-description {
  font-style: italic;
}

.testimonial .testimonial-description p {
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  margin: 0.25em 0;
  padding: 5px 20px 5px 48px;
  line-height: 1.45;
}

.testimonial .testimonial-description p:before {
  content: "\201c";
  display: block;
  padding-left: 10px;
  font-family: "Georgia";
  font-size: 90px;
  color: #6c757d;   /* muted */
  position: absolute;
  top: -30px;
  left: -18px;
}

.testimonial .testimonial-stars {
  text-align: center;
}

.testimonial .testimonial-name {
  font-size: 1.2rem;
  font-weight: 500;
  text-align: center;
}

.google-stars {
  font-size: 1.2rem;
  color: #FFCD03;
}

.trustpilot-stars {
  font-size: 1.2rem;
  color: #21A56E;
}

.review {
  border: 1px solid #f0f0f0;
  border-radius: 10px;
}

.review-img {
  border-radius: 10px;
}

.position-stars {
  position: absolute;
  top: 33px;
  left: 15px;
}

/* Section Blog */
.btn.btn-filter {
  color: #d99811;
  background-color: #fff;
  border-color: #d99811;
  &:hover {
      color: #d99811;
      border-color: #d99811;
  }
  &:focus {
      color: #d99811;
      border-color: #d99811;
  }
  &:active {
      color: #d99811;
      border-color: #d99811;
  }
}
.btn.btn-filter.active {
  color: #fff;
  background-color: #d99811;
  border-color: #d99811;
}
[data-aos="post-comment-animation"] {
  opacity: 0;
  transform: translateY(100px);
  transition-property: transform, opacity;
  &.aos-animate {
    opacity: 1;
    transform: translateY(0);
  }
}
.post-comment {
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.post-comment.promoted {
  -webkit-line-clamp: 6;
}

.post-view {
  overflow: hidden;
  position: relative;
  img {
    transition: all 0.2s linear;
  }
  &:hover {
    img {
      transform: scale(1.1);
    }
  }
}

.post {
  a {
   color:#d99811;
   text-decoration: none;
    &:hover {
      color:#aa780e
    }
  }

  img[style="float: left;"] {
    margin-right: 1rem;
  } 

  img[style="float: right;"] { 
    margin-left: 1rem;
  } 
  
  img {
    max-width: 100%;
    height: auto; 
    margin-top: 1rem; margin-bottom: 0.5rem;
    cursor: pointer;
  }
}

.social-buttons {
	ul {
		--bs-nav-link-padding-x: 1rem;
		--bs-nav-link-padding-y: 0.5rem;
		--bs-nav-link-font-weight: ;
		--bs-nav-link-color: var(--bs-link-color);
		--bs-nav-link-hover-color: var(--bs-link-hover-color);
		--bs-nav-link-disabled-color: var(--bs-secondary-color);
		display: flex;
		flex-wrap: wrap;
		padding-left: 0;
		margin-bottom: 0;
		list-style: none;
	}
}

.social-button {
  background-repeat: no-repeat;
  background-size: cover;
  width: 40px;
  height: 40px;
}

.facebook-button {
  background-image: url('../images/social-media/facebook.webp');
}

.twitter-button {
  background-image: url('../images/social-media/x-twitter.webp');
}

.linkedin-button {
  background-image: url('../images/social-media/linkedin.webp');
}

  /* Section Services */

.service-content {
    width: 296px;
    margin: auto;
}

.service-overlay {
  position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: #212529;
	opacity: 0.3;
}

.service-text {
  font-size: 1rem;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  background-color: #aa780e99;
  background-color: rgba(170, 120, 14, 0.6); /* Couleur secondaire à 60% d'opacité */
  transition: all 0.4s ease-in-out;
  opacity: 0;
  & p:last-child {
    margin-bottom: 0;
  }
}

.service-title {
  position: absolute;
  top: 200px;
  transition: all 0.5s ease-in-out;
  opacity: 1;
  & h2 {
    font-size: 1.25rem;
    width: 75%;
  }
}

.service-after {
  position: absolute;
  background-color: #d99811;    /* Voir couleur secondaire */
  content: '';
  bottom: 0;
  right: 0;
  height: 3.5rem;
  width: 1rem;
  transition: all 0.5s ease-in-out;
}

.card {
  box-shadow: 2px 6px 8px 0 rgba(22, 22, 26, 0.18);
  border: none;
}

.card-item {
  position: relative;
  transition: transform 0.6s ease-in-out;
} 
  
  /* Section Réalisations */
.project-view {
  overflow: hidden;
  position: relative;
  img {
    transition: all 0.2s linear;
  }
  &:hover {
    img {
      transform: scale(1.1);
    }
  }
}

.project-overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #212529;
  opacity: 0;
  transition: all 0.2s linear;
  &:hover {
    opacity: 0.7;
  }
}
  
    /* Section Contact (voir pour les Couleurs plus bas) */
#message {
  max-height: 296px;
}

.fs-24 {
  font-size: 1.5rem;
}

.contact-block::after {
  position: absolute;
  background-color: #dddddd;
  content: "";
  bottom: 0;
  left: 12.5%;
  height: 1px;
  width: 75%;
}

.contact-block:last-child::after {
  background-color: transparent !important;
}

.legend-color {
  min-width: 24px;
  max-width: 24px;
  height: 24px;
  background-color: rgba(51, 136, 255, 0.3);
  border: 1px solid rgba(51, 136, 255, 0.6);
  border-radius: 1rem;
}

#map {    /* Id leaflet */
  height: 500px;
}

.legend {
  background-color: rgba(255, 255, 255, 0.75);
  position: absolute;
  z-index: 800;
  border: 2px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  bottom: 20px;
  left: 10px;
}

.leaflet-touch .leaflet-bar a {  /* Classes leaflet pour taille boutons + et - */
  width: 35px;
  height: 35px;
  line-height: 35px;
}

.form-control {
  &.is-invalid, &.is-valid{
    background-image: none;
  }
}

.form-check-input.is-valid {
  border-color: rgba(0, 0, 0, 0.25);
}

.form-check-input.form-check-color:checked {  /* Couleur primaire ou secondaire selon la couleur des labels */
  background-color: #d99811;
  border-color: #d99811;
}

.form-check-input.form-check-color:focus {   /* Couleur primaire ou secondaire selon le cas */
  border-color: #d99811;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(217, 152, 17, 0.25);  /* opacité plus faible */
}

.btn-call-link {
  width: 48px;
  transition: width 0s, border-radius 0s;
  padding: 6px 11px;
  border-width: 3px;
  border-radius: 50%;
  background-color: #d99811;
  border-color: #d99811;
  color: #fff;
  &.active{
    background-color: #d99811;
    border-color: #d99811;
    color: #fff;
  }
  &:hover, &:focus, &:active {
    background-color: #d99811 !important; /* luminosité HSL diminuée de 10% */
    border-color: #d99811 !important; /* luminosité HSL diminuée de 10% */
    color: #fff !important;
  }
  .phone-number {
    opacity: 0;
  }
}

/* Agenda */
.agenda-form {
  max-width: 336px;
}

.agenda-grid {
  grid-template-columns: repeat(auto-fill, 100px);
}

.agenda-item {
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background-color: white;
  border-radius: 0.375rem;
  box-shadow: 0 0 0 1px rgba(0,0,0,.12);
  &.booked {
    background-color: rgba(1, 73, 75, 0.07) !important;
    box-shadow: rgba(1, 73, 75, 0.8) 0px 0px 0px 1px !important;
    color: rgb(1, 73, 75) !important;
    opacity: 0.65;
  }
  &.closed {
    background-color: transparent !important;
    box-shadow: 0 0 0 1px rgba(0,0,0,0.4) !important;
    opacity: 0.65;
    color: #6c757d !important;
  }
}

  /* Footer */
/* Couleur footer générique (si couleurs primaires ou secondaires ne conviennent pas */
.bg-footer {  /* main-footer */
  background-color: #343a40;
}

.bg-footer-dark {  /* sub-footer */
  background-color: #1e2124;
}

.footer-link {  /* Liens réseaux sociaux */
  color: white;
  text-decoration: none;
  &:hover {
    color: rgb(225, 225, 225);
  }
}

#sub-footer a:hover {
  font-weight: 600;
}

/* COULEURS à éditer puis classes à éventuellement modifier dans les vues */
  /* Couleur primaire - 1 couleur du logo - Peut servir de couleur sombre de titre/texte si assez foncée sinon voir couleur tertiaire */

.bg-color-body {
  background-color : #f1f2f4;
}

.text-color-primary {
  color:#01494b;
}

.bg-color-primary {
  background-color: #01494b !important;
}

.border-color-primary {
  border-color: #01494b !important;
}

.bg-color-primary-light {
  background-color: hsl(182, 97%, 95%) !important;  /* Luminosité augmenté à 85-90% */
}

.bg-color-primary-dark {
  background-color: hsl(182, 97%, 10%) !important;  /* Luminosité diminué de 10-15% */
}

.btn-color-primary {
  background-color: #01494b;
  border-color: #01494b;
  color: #fff;
  &.active{
    background-color: #01494b;
    border-color: #01494b;
    color: #fff;
  }
  &:hover, &:focus, &:active {
    background-color: hsl(182, 97%, 10%) !important; /* luminosité HSL diminuée de 10% */
    border-color: hsl(182, 97%, 10%) !important; /* luminosité HSL diminuée de 10% */
    color: #fff !important;
  }
}

.btn-color-primary-outline {
  background-color: transparent;
  border-color: #01494b;
  color: #01494b;
  &:hover, &:focus, &:active {
    background-color: #01494b !important;
    border-color: #01494b !important;
    color: #fff !important;
  }
}

.link-color-primary {
  color:#01494b !important;
  transition: all 0.3s ease-in-out;
  &:hover {
    font-weight: 600;
  }
}

.nav-link.text-color-primary {  /* Alternative "couleur primaire" de titre si besoin */
  color: #01494b !important;
  font-weight: normal;
    &:hover, &.active {
      color: #d99811 !important;  /* Couleur secondaire au survol */
      font-weight: 600;
    }
    &::before {
      display: block;
      content: attr(title);
      font-weight: 600;
      height: 0;
      overflow: hidden;
      visibility: hidden;
    }
}

.form-color-primary {
  background-color: rgba(1, 73, 75, 0.05) !important;
  &:focus {
    color: #001919;
    border-color: rgba(1, 73, 75, 0.5);
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(1, 73, 75, 0.25);
  }
}

  /* Couleur secondaire - Ici, 2ème couleur du logo : #343a40 + variantes avec luminosité HSL diminuée de 10% ou opacité plus faible
  (Peut nous servir également de couleur par défaut pour titres/link/footer si assez sombre)
      OU couleur d'accentuation choisie par client voire nous
  */

.text-color-secondary {
  color:#d99811;
}

.bg-color-secondary {
  background-color: #d99811 !important;
}

.border-color-secondary {
  border-color: hsl(41, 85%, 46%) !important;
  border-color: #d99811 !important;
}

.bg-color-secondary-light {
  background-color: hsl(41, 85%, 90%) !important;  /* Luminosité augmenté à 85-90% */
}

.bg-color-secondary-dark {  /* Par exemple, subfooter */
  background-color: #aa780e       /* luminosité HSL diminuée de 10% */
}

.btn-color-secondary {
  background-color: #d99811;
  border-color: #d99811;
  color: #fff;
  &.active{
    background-color: #d99811;
    border-color: #d99811;
    color: #fff;
  }
  &:hover, &:focus, &:active {
    background-color: rgb(170, 120, 14) !important;  /* luminosité HSL diminuée de 10% */
    border-color: hsl(41, 85%, 36%) !important;  /* luminosité HSL diminuée de 10% */
    color: #fff !important;
  }
}

.btn-color-secondary-outline {
  background-color: transparent;
  border-color: #d99811;
  color: #d99811;
  &:hover, &:focus, &:active {
    background-color: #d99811 !important;
    border-color: #d99811 !important;
    color: #fff !important;  /* Ajuster selon la couleur du bg */
  }
}

.form-color-secondary {
  background-color: rgba(170, 120, 14, 0.05) !important;  /* opacité plus faible */
  &:focus {
    color: #001919;
    border-color: rgba(170, 120, 14, 0.5);  /* opacité plus faible */
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(170, 120, 14, 0.25);  /* opacité plus faible */
  }
}

.nav-link.text-color-secondary {  /* 1ère alternative de "noir" de titre si la couleur secondaire est un gris foncé */
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, font-weight .3s ease-in-out;
  &.inactive {
    color: #d99811 !important;
    font-weight: normal;
  }
  &:hover, &.active {
    color: #01494b !important;  /* Couleur primaire au survol */
    font-weight: 600;
  }
  &::before {
    display: block;
    content: attr(title);
    font-weight: 600;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
}

.link-color-secondary {
  color:#d99811 !important;
  transition: all 0.3s ease-in-out;
  &:hover {
    font-weight: 600;
  }
}

  /* Couleur tertiaire = éventuelle 3ème couleur différente ou basée par exemple sur la couleur primaire si celle-ci pas assez foncée */
  /* Non utilisée ici dans le modèle */
.text-color-tertiary {
  color:hsl(180, 100%, 5%)   /* Exemple ici : primaire à 5-10% de luminosité */
}

.bg-color-tertiary {
  background-color: #001919;
}

.btn-color-tertiary {
  background-color: #001919;
  border-color: #001919;
  color: #fff;
  &.disabled {
    background-color: transparent;
    color: #001919;
  }
  &.active{
    background-color: #001919;
    border-color: #001919;
    color: #fff;
  }
  &:hover, &:focus, &:active {
    background-color: #01494b; /* luminosité HSL diminuée de 10% */
    border-color: #01494b;  /* luminosité HSL diminuée de 10% */
    color: #fff;
  }
}

.nav-link.text-color-tertiary {
  color: #001919 !important;
  font-weight: normal;
  &:hover, &.active {
    color: #01494b !important;  /* Couleur primaire ou secondaire au survol */
    font-weight: 600;
  }
  &::before {
    display: block;
    content: attr(title);
    font-weight: 600;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
}

.link-color-tertiary {
  color:#001919 !important;
  transition: all 0.3s ease-in-out;
  &:hover {
    color: #01494b !important;  /* Couleur primaire ou secondaire au survol */
    font-weight: 600;
  }
}
  
@media (min-width: 360px) {
  .service-content {
    width: 320px;
  }

  .service-title {
    top: 225px;
  }
}
  
  
@media (min-width: 400px) {
  .service-text {
    font-size: 1rem;
  }

  .value-description {
    padding: 0 1.5rem;
  }
}

@media (min-width: 472px) {
  .agenda-form {
    max-width: 448px;
  }
}

@media (min-width: 500px) {
  .service-content {
    width: 420px;
  }

  .service-title {
    top: 300px;
    & h2 {
      font-size: 1.5rem;
    }
  }

  .service-text {
    font-size: 1.25rem;
  }

  .service-after {
    height: 5rem;
  }

  .value-description {
    padding: 0 2.5rem;
  }
}
  
@media(min-width: 576px) {
  .top-header {
    height: 37px;
  }

  .banner-text-min-h {
    min-height: 130px;
  }

  // .banner-text-min-hb {
  //   min-height: 68px;
  // }

  .service-text {
    font-size: 0.925rem;
  }
  
  .service-after {
      height: 3.5rem;
  }

  .service-title {
    top: 155px;
    & h2 {
      font-size: 1.2rem;
      width: 85%;
    }
  }

  .service-content {
    width: auto;
  }
}

@media(max-width: 767px)  {
  .post {
    img {
      float: none !important;
      display: block;
      margin-left: auto !important;
      margin-right: auto !important;
    }
  }
}
  
@media(min-width: 768px)  {
  .contact-block::after {
    background-color: transparent !important;
  }

  .contact-odd:nth-child(even)::before {
    position: absolute;
    background-color: #dddddd;
    content: "";
    bottom: 0;
    left: 0;
    height: 100%;
    width: 1px;
  }

  .legend {
    bottom: 10px;
  }

  .logo-size {
      max-height: 50px;
  }

  .nav-min-height { /* NEW */
    min-height: 76px;
  }

  .toggler-min-height { /* NEW */
    min-height: 60px;
  }

  .mt-main {  /* Margin top du main = hauteur header*/
    margin-top: 76px;
  }

  // .banner-text-min-h {
  //   min-height: 120px;
  // }

  // .banner-text-min-hb {
  //   min-height: 58px;
  // }

  .service-text {
    font-size: 1rem;
  }

  .service-after {
      height: 5rem;
  }

  .service-title {
    top: 220px;
    & h2 {
      font-size: 1.25rem;
      width: 85%;
    }
  }

  .modal-img {
    max-width: 75%;
  }
}
  
@media (max-width: 991px) {
  .aos-img {
      opacity: 1 ; 
      transform : none;
      margin-bottom: 1rem; 
    }
}
  
@media(min-width: 992px) {
  .separator-bs::after {
      left: 0;
  }

  #text-over-left {
      position: absolute;
      left: 0;
      opacity: 1 ; 
      transform : none;
  }

  .banner-text {
    width: 700px;
  }

  .about-height {
    min-height: 500px;
  }

  .service-text {
      font-size: 1rem;
  }

  .service-after {
      height: 5.5rem;
  }

  .service-title {
    top: 170px;
    & h2 {
      font-size: 1.5rem;
      width: 85%;
    }
  }

  .img-carousel-mobile  {
    height: auto !important;
    max-width: 100% !important;
  }

  .review-link {
    margin-right: 32px;
  }

  [data-aos="post-comment-animation"] {
    opacity: 0;
    transform: translateX(100px);
    transition-property: transform, opacity;
    &.aos-animate {
      opacity: 1;
      transform: translateX(0);
    }
  }
  .post-comment.promoted {
    -webkit-line-clamp: 5;
  }

  .agenda-grid {
    grid-template-columns: repeat(auto-fill, 108px);
  }
  
  .agenda-item {
    height: 108px;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .contact-even:nth-child(even)::before {
    position: absolute;
    background-color: #dddddd;
    content: "";
    bottom: 0;
    left: 0;
    height: 100%;
    width: 1px;
  }
} 
  
@media (min-width: 992px) and (max-width: 1199px) {
  .mb-lg_xl-10 {
    margin-bottom: 10rem !important;
  }
} 
  
@media (max-width: 1199px) {
  #banner-img {
    max-width: none;
    width: auto;
    height: 300px;
  }
}
  
@media(min-width: 1200px)  {
  .logo-size {
      max-height: 55px;
  }

  .nav-min-height { /* NEW */
    min-height: 81px;
  }

  .mt-main {  /* Margin top du main = hauteur header*/
    margin-top: 81px;
  }

  // .banner-info {
  //   bottom: 10%;
  // }

  #text-over-left {
      top:auto;
  }

  .about-height {
    min-height: 550px;
  }

  .service-after {
    height: 5.5rem;
  }

  .service-title {
    top: 197px;
  }

  .service-content:hover {
    & .service-text {
      transition: all 0.5s ease-in-out;
      opacity: 1;
    }

    & .service-title {
      transition: all 0.4s ease-in-out;
      opacity: 0;
    }

    & .service-after {
      transition: all 0.4s ease-in-out;
      background-color: transparent;
    }
  }

  .modal-img {
    max-width: 60%;
  }

  .post-comment.promoted {
    -webkit-line-clamp: 6;
  }

  .contact-even:nth-child(2)::before {
    position: absolute;
    background-color: #dddddd;
    content: "";
    bottom: 0;
    left: 0;
    height: 100%;
    width: 1px;
  }
  
  .contact-even:nth-child(2n+3)::before {
    position: absolute;
    background-color: #dddddd;
    content: "";
    bottom: 0;
    left: 0;
    height: 100%;
    width: 1px;
  }
  
  .btn-call-link:hover {
    width: 100%;
    border-radius: 2rem;
    transition: width 1s;
    .phone-number {
      opacity: 1;
    }
  }
}
      
@media (min-width: 1400px) {
  .banner-text-min-h {
    min-height: calc(65px + 7vw);
  }

  // .banner-text-min-hb {
  //   min-height: 63px;
  // }

  .btn-xxl-large {
    --bs-btn-padding-y: 0.5rem;
    --bs-btn-padding-x: 1rem;
    --bs-btn-font-size: 1.25rem;
    --bs-btn-border-radius: 0.5rem;
  }

  .about-height {
    min-height: 600px;
  }

  .service-title {
    top: 225px;
    & h2 {
      font-size: 1.75rem;
      width:75%
    }
  }

  .service-text {
    font-size: 1.25rem;
  }

  .service-after {
      height: 7rem;
  }
}
